<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="类型:">
          <el-select 
						v-model="searchForm.type" 
						@visible-change="visibleLeve"
						ref="visibleRef"
						@keyup.enter.native="seach"
						clearable>
            <el-option value="旅游奖励"></el-option>
            <el-option value="有薪假期"></el-option>
            <el-option value="生日补贴"></el-option>
            <el-option value="结婚补贴"></el-option>
            <el-option value="生育补贴"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次:">
          <el-select 
						v-model="searchForm.levels" 
						@visible-change="visibletype"
						ref="selectRef"
						@keyup.enter.native="seach"
						clearable>
            <el-option value="高层管理"></el-option>
            <el-option value="中层管理"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补贴标准:">
          <el-input 
						v-model="searchForm.standard" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button typr="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="类型" prop="type" align="center" />
      <el-table-column label="年限" prop="year" align="center" />
      <el-table-column label="补贴标准" prop="standard" align="center" />
      <el-table-column label="层次" prop="levels" align="center" />
      <el-table-column label="额外红包" prop="added" align="center" />
      <el-table-column label="合计" prop="total" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="30%"
			v-dialogDrag
			:close-on-click-modal='false'
      title="添加："
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        :rules="rules"
        class="form-item-w-100"
      >
        <el-form-item label="类型:" prop="type">
          <el-select v-model="dynamicValidateForm.type" @change="typeChange" clearable>
            <el-option value="旅游奖励"></el-option>
            <el-option value="有薪假期"></el-option>
            <el-option value="生日补贴"></el-option>
            <el-option value="结婚补贴"></el-option>
            <el-option value="生育补贴"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年限:" prop="year">
          <el-select v-model="dynamicValidateForm.year" clearable>
            <el-option value="1-3年"></el-option>
            <el-option value="3-5年"></el-option>
            <el-option value="5年及以上"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次:" prop="levels" v-if="isShow">
          <el-select v-model="dynamicValidateForm.levels" clearable>
            <el-option value="高层管理"></el-option>
            <el-option value="中层管理"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="额外红包:" prop="added" v-if="isShow" >
          <el-input v-model.trim="dynamicValidateForm.added" clearable/>
        </el-form-item>
        <el-form-item label="补贴标准:" prop="standard">
          <el-input v-model.trim="dynamicValidateForm.standard" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 导入 -->
    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
    ></Import>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { part } from "@/utils/mixins";
import Import from "@/components/Import/index.vue";
import {
  special_list,
  special_input,
  special_save,
  special_delete,
  wages_importsave,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "special",
  components: { Import },
  props: {
    importSaveUrl: {
      default: () => wages_importsave,
    },
  },
  data() {
    return {
      // pulic
      itemId: null, //首表格项id
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      isShow: false, // 选择结婚生育显示
      multipleSelection: [], //选中的表格项
      searchForm: {},
      // 增加修改
      dynamicValidateForm: {}, // 对象
      showAddModal: false, //显示表单框
      importDialog: false, //导入框

      // 规则校验
      rules: {
        type: [{ required: true, message: "必填字段", trigger: "change" }],
        year: [{ required: true, message: "必填字段", trigger: "change" }],
        standard: [{ required: true, message: "必填字段", trigger: "change" }],
      },
    };
  },
  created() {
    // 获取列表
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]), //关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleLeve(e){
			if(!e) {
				this.$refs.visibleRef.blur();
			}
		},
		visibletype(e) {
			if(!e) {
				this.$refs.selectRef.blur();
			}
		},
		reset() {
			this.searchForm = {
				type: null,
				levels: null,
				standard: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //导入 click
    btnImport() {
      this.importDialog = true;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: special_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },

    // 选择结婚生育让层次, 额外红包显示
    typeChange(val) {
      if (val === "结婚补贴" || val === "生育补贴") {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.dynamicValidateForm.added = 0;
        this.dynamicValidateForm.levels = "-";
      }
    },

    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //添加click
    btnAdd() {
      this.dynamicValidateForm = {};
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },

    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: special_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vspecial;
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: special_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
            }
          ).then((res) => {
            if (res.data.code === "200") {
              this.showAddModal = false;
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "红星必填",
          });
        }
      });
    },

    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: special_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //刷新 click
    btnReload() {
      this.getDataList();
    },
  },
};
</script>

<style  lang="scss">
</style>